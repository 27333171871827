.sidebar_main {
  height: calc(100% - 3rem);
  display: flex;
}
.sidebar_main {
  position: relative;
}
.goBack {
  position: absolute;
  top: 1rem;
  width: 0;
}
.goBack svg {
  background: #fff;
  border: 1px solid #efefef;
  border-radius: 50%;
  cursor: pointer;
}
.gobackRight {
  left: 15rem;
  transition: left 500ms;
}
.gobackLeft {
  left: 3.2rem;
  transition: left 500ms;
}
