.dashboardComponent {
  display: flex;
  flex-direction: column;
}
.dashboardChartWrapper {
  height: calc(100% - 3rem);
  flex-grow: 1;
}
.dashboardChartWrapper > .container-fluid,
.stackChartCont,
.stackChartCont #inboundchart {
  height: 100%;
}
.chartContainer {
  height: 50%;
}
.chartInnerContainer {
  height: 100%;
}
.donutChartContainer {
  height: calc(100% - 2.5rem);
}
