.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /*background-color: rgba(128, 128, 128, 0.5); /* Gray background with transparency */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure it overlays other content */
    color: brown;
}

.custom-circular-progress {
    color: gray; /* Example: green */
}
