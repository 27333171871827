table.source tr th,
table.source tr td {
  font-size: 1.2rem;
  font-weight: 500;
  padding: 0.5rem 0rem;
}
table.source tr th:first-of-type {
  color: rgb(var(--text-heading1));
  max-width: 8rem;
  white-space: nowrap;
}
table.source tr td {
  font-size: 1rem;
  font-weight: 400;
}
.sorceDataDiv button {
  margin: 0.5rem 0rem;
}
.showInputText.form-control:disabled,
.showInputText.form-control[readonly] {
  background: transparent;
  border-color: transparent;
}
p.mailInfoUser {
  font-size: 0.8rem;
  color: rgb(var(--text-active));
}
input.form-control.timer {
  display: inline-block;
  width: 5rem;
}
input.form-check-input:disabled {
  /* opacity: 1; */
}
.IntegDetailsComponent button.editNsave {
  background-color: rgb(var(--text-heading2));
}
.IntegDetailsComponent button.editNsave:hover {
  background-color: rgb(var(--text-heading1));
}
.IntegDetailsComponent table {
  width: 100%;
}
.MuiDialog-container .css-1j2bu2r-MuiDialogActions-root {
  justify-content: center;
}
.MuiDialog-container button.PrivateDateTimePickerToolbar-penIcon {
  display: none;
}
td.dateTimePickerTd div.Mui-disabled fieldset.MuiOutlinedInput-notchedOutline {
  border-color: transparent;
}
td.dateTimePickerTd div.Mui-disabled input {
  padding-left: 0;
  color: #000;
  -webkit-text-fill-color: #000;
}
