.mainSectionHeading.withAddnew {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.passTd {
  position: relative;
  display: flex;
  align-items: center;
}
.passTd svg {
  position: absolute;
  right: 1.5rem;
  cursor: pointer;
}
.passTd p.passStrength {
  position: absolute;
  right: 4rem;
  margin-bottom: 0;
}
.passTd p.error {
  color: rgb(var(--text-active));
  font-size: 0.9rem;
  line-height: 1.1rem;
}
