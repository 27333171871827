html,
body,
div#root,
div.theme,
div.homeComponent,
div.layoutComponent {
  height: 100%;
}
body {
  color: #000;
}
.mainSection {
  height: 100%;
  /* padding: 0 0.5rem; */
  overflow-y: auto;
}
.mainSectionHeading {
  color: rgb(var(--text-heading1));
  font-size: 1.5rem;
  padding: 0.5rem 1rem;
  padding-bottom: 0;
}
.projectRoutes {
  color: rgb(var(--text-integ-route));
  /* font-size: 0.85rem; */
  padding: 0.5rem 1rem;
  padding-bottom: 0;
}
.projectRoutes a {
  text-decoration: none;
  color: rgb(var(--text-heading1));
}
.projectRoutes a:hover {
  text-decoration: underline;
  color: rgb(var(--text-integ-route-hover));
}
.pointer {
  cursor: pointer;
}
.hide {
  display: none;
}
input.form-control:focus,
input.form-control:focus-visible {
  outline: 0;
  box-shadow: none;
  border-color: rgb(var(--text-heading2));
  background-color: #fff;
}
.noInternet,
.noPageFound {
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.noInternet img,
.noPageFound img {
  max-height: 40%;
  margin-bottom: 1rem;
  max-width: 40%;
}
.noInternet p,
.noPageFound p {
  font-size: 2rem;
  color: rgb(var(--text-heading2));
  margin-bottom: 0;
}
.noInternet p:last-of-type {
  font-size: 1rem;
  margin-bottom: 1rem;
}
.noInternet button,
.noInternet button:hover {
  color: #fff;
  font-size: 1rem;
  background-color: rgb(var(--text-heading2));
  border: 1px solid rgb(var(--text-heading2));
}
.fullscreenLoading {
  justify-content: center;
  align-items: center;
}
/* Scrollbar */
.mainSection::-webkit-scrollbar,
.stackChartCont::-webkit-scrollbar,
.sidebarDataSection::-webkit-scrollbar,
.scheduleSearchResultBodyInner::-webkit-scrollbar,
.scheduleSearchPageScreen::-webkit-scrollbar,
.statmentScreen .MuiTableContainer-root::-webkit-scrollbar,
.graphLowerportionDiv::-webkit-scrollbar,
.shipmentSearchBody::-webkit-scrollbar {
  width: 0.4rem;
  height: 0.4rem;
}
.mainSection::-webkit-scrollbar-thumb,
.stackChartCont::-webkit-scrollbar-thumb,
.sidebarDataSection::-webkit-scrollbar-thumb,
.scheduleSearchResultBodyInner::-webkit-scrollbar-thumb,
.scheduleSearchPageScreen::-webkit-scrollbar-thumb,
.statmentScreen .MuiTableContainer-root::-webkit-scrollbar-thumb,
.graphLowerportionDiv::-webkit-scrollbar-thumb,
.shipmentSearchBody::-webkit-scrollbar-thumb {
  background: rgb(var(--text-heading2));
}
.mainSection::-webkit-scrollbar-track,
.stackChartCont::-webkit-scrollbar-track,
.sidebarDataSection::-webkit-scrollbar-track,
.scheduleSearchResultBodyInner::-webkit-scrollbar-track,
.scheduleSearchPageScreen::-webkit-scrollbar-track,
.statmentScreen .MuiTableContainer-root::-webkit-scrollbar-track,
.graphLowerportionDiv::-webkit-scrollbar-track,
.shipmentSearchBody::-webkit-scrollbar-track {
  background: rgb(var(--text-heading1));
}
.mainSection,
.stackChartCont,
.sidebarDataSection,
.scheduleSearchResultBodyInner,
.scheduleSearchPageScreen,
.statmentScreen .MuiTableContainer-root,
.graphLowerportionDiv,
.shipmentSearchBody {
  scrollbar-color: rgb(var(--text-heading1)) rgb(var(--text-heading2));
  scrollbar-width: thin;
}
/* media query */
@media (max-width: 576px) {
  html {
    font-size: 8px;
  }
}
@media (min-width: 576px) {
  html {
    font-size: 10px;
  }
}
@media (min-width: 750px) {
  html {
    font-size: 11px;
  }
}
@media (min-width: 1050px) {
  html {
    font-size: 12px;
  }
}
@media (min-width: 1150px) {
  html {
    font-size: 13px;
  }
}
@media (min-width: 1200px) {
  html {
    font-size: 14px;
  }
}
@media (min-width: 1250px) {
  html {
    font-size: 15px;
  }
}
@media (min-width: 1350px) {
  html {
    font-size: 16px;
  }
}
@media (min-width: 1450px) {
  html {
    font-size: 17px;
  }
}
@media (min-width: 1550px) {
  html {
    font-size: 18px;
  }
}
@media (min-width: 1650px) {
  html {
    font-size: 19px;
  }
}
@media (min-width: 1750px) {
  html {
    font-size: 20px;
  }
}
@media (min-width: 1850px) {
  html {
    font-size: 21px;
  }
}
@media (min-width: 1900px) {
  html {
    font-size: 22px;
  }
}
@media (min-width: 2000px) {
  html {
    font-size: 23px;
  }
}
@media (min-width: 2100px) {
  html {
    font-size: 24px;
  }
}
@media (min-width: 2200px) {
  html {
    font-size: 25px;
  }
}
@media (min-width: 2300px) {
  html {
    font-size: 26px;
  }
}
@media (min-width: 2350px) {
  html {
    font-size: 27px;
  }
}
@media (min-width: 2450px) {
  html {
    font-size: 28px;
  }
}
@media (min-width: 2500px) {
  html {
    font-size: 29px;
  }
}
@media (min-width: 2550px) {
  html {
    font-size: 30px;
  }
}
@media (min-width: 2600px) {
  html {
    font-size: 31px;
  }
}
@media (min-width: 2700px) {
  html {
    font-size: 32px;
  }
}
@media (min-width: 2800px) {
  html {
    font-size: 33px;
  }
}
