.loginComponent,
.loginComponent > .row {
  height: 100%;
}

.loginComponent > div > div > img {
  margin: 1rem;
}

.loginFormSection {
  display: flex;
  justify-content: center;
  align-items: center;
}
.loginDiv {
  max-width: 80%;
  width: 25rem;
  background-color: white;
  padding: 1.5rem;
  border-radius: 1rem;
}

.loginDiv button,
.loginDiv button:hover {
  background: linear-gradient(
    100deg,
    rgb(var(--text-heading2)),
    rgb(var(--text-heading1))
  );
  color: #fff;
  width: 100%;
}
.loginFormSection h5 {
  font-size: 2rem;
  line-height: 2.5rem;
  margin-bottom: 2rem;
  background: -webkit-linear-gradient(
    100deg,
    rgb(var(--text-heading2)),
    rgb(var(--text-heading1))
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.loginbannerSection {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.loginbannerSection img {
  max-width: 65%;
  max-height: 65%;
}
.loginFormSection a {
  background: -webkit-linear-gradient(
    100deg,
    rgb(var(--text-heading2)),
    rgb(var(--text-heading1))
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.loginFormSection .form-floating {
  align-items: center;
  display: flex;
}
.loginFormSection .form-floating svg {
  position: absolute;
  right: 0.5rem;
  cursor: pointer;
}
.loginFormSection .form-floating p {
  position: absolute;
  right: 3rem;
  margin-bottom: 0;
}
.loginFormSection .form-floating + p.error {
  color: rgb(var(--text-active));
  font-size: 0.9rem;
  line-height: 1.1rem;
}
