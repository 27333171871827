.sidebarcomp {
  width: 16rem;
  border-right: 1px solid #efefef;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}
.closeSidebar {
  transition: width 500ms;
  width: 4rem;
}
.openSidebar {
  transition: width 500ms;
  /* margin-left: -0; */
}
.minMainSection {
  transition: width 500ms;
  width: calc(100% - 16rem);
}
.maxMainSection {
  transition: width 500ms;
  width: calc(100% - 4rem);
}
.itemInActive {
  text-decoration: none;
}
.sidebarList a.active li.MuiListItem-root {
  background: rgb(235, 236, 240);
}
ul.sidebarList li.MuiListItem-root {
  padding: 0.5rem 0rem;
}
ul.sidebarList li.MuiListItem-root div.MuiListItemIcon-root {
  min-width: 4rem;
  justify-content: center;
}
li.sidebarHeading {
  color: rgb(var(--text-heading1));
  padding-bottom: 0;
  padding-top: 0;
}
.sidebarHeading span.MuiListItemText-primary {
  /* font-size: 1.8rem; */
  font-size: 0.9rem;
  overflow: hidden;
  text-overflow: ellipsis;
}
ul.sidebarList
  li.MuiListItem-root.sidebarHeading
  div.MuiListItemIcon-root
  svg.MuiSvgIcon-root {
  color: #fff;
  background: rgb(var(--text-heading1));
  font-size: 2rem;
  padding: 0.25rem;
  border-radius: 0.5rem;
}
ul.sidebarList li.MuiListItem-root div.MuiListItemIcon-root img {
  height: 1.8rem;
  width: 1.8rem;
}
ul.sidebarList li.MuiListItem-root div.MuiListItemIcon-root svg {
  font-size: 1.8rem;
}
.sidebarList a, .sidebarList .MuiListItemButton-root {
  text-decoration: none;
  color: rgb(var(--text-heading1));
}
.sidebarList a.active, .sidebarList .MuiListItemButton-root.active {
  color: rgb(var(--text-active));
}
.integrationSidebarList .sidebarHeading {
  font-size: 1.2rem;
  margin-bottom: 1rem;
}
.integrationSidebarList .sidebarHeading img {
  width: 2rem;
  height: 2rem;
  border-radius: 0.5rem;
  margin-right: 0.5rem;
}
.sidebarList li.MuiListItem-root {
  white-space: nowrap;
}
