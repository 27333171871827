.infoEmail {
  color: #fd7e14;
}
.infoEmail p {
  margin-bottom: 0;
}
.infoEmail ol {
  margin-bottom: 0rem;
}
fieldset.fieldset {
  border: 2px groove #dfdfdf;
  padding: 0 1rem;
  margin-bottom: 1rem;
}
fieldset.fieldset legend {
  float: unset;
  width: auto;
  padding: 0 0.5rem;
  margin-bottom: 0;
}
.chipList {
  margin: 0;
  padding: 0.5rem 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
}
p.emailError {
  padding: 0 0.75rem;
  margin-bottom: 0;
  color: rgb(var(--text-error));
}
fieldset.fieldset .row .col-4 {
  display: flex;
  align-items: center;
}
.notifiPage .row {
  margin-bottom: 1rem;
}
.PhoneInputCountry + input,
.PhoneInputCountry + input:focus-visible {
  border: 0;
  outline: 0;
}
.PhoneInput.telInValid {
  border-color: rgb(var(--text-error));
}
.PhoneInput.telValid {
  border-color: rgb(var(--text-success));
}
