.headerComponent {
  height: 3rem;
  padding: 0.25rem 1rem;
  box-shadow: 0 6px 20px #3b3f4e0d;
  display: flex;
}
.menubar_Logo {
  flex-grow: 4;
  display: flex;
  align-items: center;
}
.search_User {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.menubar_Logo {
  height: 100%;
}
svg.menuBar {
  /* font-size: 2rem; */
  margin-right: 1.5rem;
  cursor: pointer;
  color: rgb(var(--text-active));
}
.headerLogo {
  max-height: 100%;
}
svg.userIcon {
  font-size: 2rem;
  margin-left: 1.5rem;
  cursor: pointer;
  color: rgb(var(--text-active));
}
.headersearch {
  position: relative;
  display: flex;
  align-items: center;
  flex-grow: 3;
}
.headersearch input.form-control {
  padding-left: 2rem;
  border-radius: 0.75rem;
  padding-right: 2rem;
}
.headersearch img {
  position: absolute;
  left: 0.5rem;
  cursor: pointer;
}
.headersearch svg.searchClearIcon {
  position: absolute;
  right: 0.5rem;
  cursor: pointer;
}
