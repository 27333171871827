/* .heading {
  color: var(--text);
  background: var(--background);
} */
/* #0175b3 #c30331 */
:root {
  /* #015480 */
  --text-heading1: 67, 137, 189;
  --text-heading2: 41, 35, 92;
  --text-active: 41, 35, 92;
  --text-integ-route: 107, 119, 140;
  --text-integ-route-hover: 69, 69, 69;
  --text-success: 47, 156, 10;
  --text-error: 201, 44, 44;
}
.form-label {
  font-weight: 500;
}

.MuiTablePagination-toolbar p {
  margin: 0;
}

.main-section-sub-heading {
  font-size: 18px;
  display: flex;
  gap: 10px;
  margin: 10px 15px;
  color: #000;
}

.seprated-title {
  display: inline-flex;
  gap: 10px;
  color: #000;
  margin-left: 10px;
  font-size: 1rem;
}

.filters-wrapper {
  width: 100%;
}

.filters-wrapper > button {
  float: right;
}

.filters-collapse.MuiCollapse-entered {
  background: #ebecf0;
  border: 1px solid #cccccc;
}

.filters-collapse {
  padding: 0;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
}

.filters-collapse .expanded {
  padding: 20px;
}

.rs-picker-toggle-textbox + .rs-picker-toggle-value {
  color: #000 !important;
}

.rs-picker-daterange-predefined .rs-stack-item {
  border: 1px solid;
  border-color: var(--rs-btn-primary-bg);
  width: 100%;
  border-bottom: 0;
}

.rs-picker-daterange-predefined .rs-stack-item button {
  color: var(--rs-btn-primary-bg);
  width: 100%;
  transition: 0.1s linear;
  border-radius: 0;
}

.rs-picker-daterange-predefined .rs-stack-item button:hover {
  text-decoration: none;
  background: var(--rs-btn-primary-bg);
  color: #fff;
}

.rs-picker-daterange-predefined .rs-stack-item:last-child {
  border-bottom: 1px solid;
}

.rs-calendar-table-cell-selected .rs-calendar-table-cell-content, .rs-btn-primary {
  background: var(--rs-btn-primary-bg) !important;
}

.daterange-filter .rs-stack-item {
  width: 100%;
}

.passId {
  position: relative;
}

.passId svg, .passId p {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
}

.passId p {
  right: 40px;
}