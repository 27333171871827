/* .integrationComponent .tableParent td button {
  background-color: rgb(var(--text-heading2));
}
.integrationComponent .tableParent td button:hover {
  background-color: rgb(var(--text-heading1));
} */
td.status button {
  /* width: 7rem; */
  min-width: auto;
  text-transform: none;
  font-weight: 400;
}
.tableParent td,
.tableParent th {
  padding: 0.5rem;
}
/* 
.tableParent button {
  background-color: #c30331;
} */
span.integActive {
  color: var(--bs-success);
}
span.integDeactive {
  color: var(--bs-danger);
}
.sorceDataDiv {
  padding: 0 1rem;
}

.source-endpoint-info {
  display: flex;
  gap: 10px;
  margin-top: 5px;
}

.source-endpoint-info .label {
  font-weight: bold;
}

.destinations-label {
  display: flex;
  gap: 10px;
}

.destinations-label .source-endpoint-info {
  font-size: 12px;
  display: none;
}

.destinations-label .source-endpoint-info.show {
  display: flex;
  line-height: 13px;
}