.mainSectionHeading.withAddnew {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.passTd {
  position: relative;
  display: flex;
  align-items: center;
}
.passTd svg {
  position: absolute;
  right: 1.5rem;
  cursor: pointer;
}
